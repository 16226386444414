<template>
   <div id="permission">
        <div class="permission">
            <div class="permission_tips">您暂无访问权限,如有需要请拨打24小时客服热线:
                <p class="permission_phone">400-931-6880</p>
            </div>
        </div>
    </div>
</template>
<script>
import { isdefine } from './service' 
export default {
    data() {
        return{

        }
    },
    mounted(){
    //     isdefine().then((res)=>{
    //   if(res.data){
    //     this.$router.push('/home')
    //   }
    // }).catch((err)=>{
    //   return err
    // })
  },
}
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");
</style>
<style lang="less">
#permission{
    width: 100%;
    height: 100%;
    flex: 1;
    .permission{
        padding-top: 100px;
        text-align: center;
        background-image: url("../../assets/error/tuichudenglu-img.png");
        background-repeat: no-repeat;
        background-position:center;
        .permission_tips {
          font-size: 14px;
          font-weight: 600;
          padding-top: 207px;
        }
        .permission_phone{
            margin-top: 10px;
            font-size: 22px;
            font-weight: 600;
            font-family: "Montserrat", sans-serif;
            color: #0095da;
        }
    }
}
</style>